import Slider from "./slider/Slider";
import photo1 from './slider/photos/photo1.png'
import photo2 from './slider/photos/photo2.png'
import photo3 from './slider/photos/photo3.png'
import photo4 from './slider/photos/photo4.png'
import photo5 from './slider/photos/photo5.png'
import small1 from './slider/photos/small1.png'
import small2 from './slider/photos/small2.png'
import small3 from './slider/photos/small3.png'
import small4 from './slider/photos/small4.png'
import small5 from './slider/photos/small5.png'

import PhotoZoom from "./photoZoom/PhotoZoom";
import p1 from './photoZoom/photos/p1.png'
import p2 from './photoZoom/photos/p2.png'
import p3 from './photoZoom/photos/p3.png'

import Gallery from './gallery/Gallery'
import c1 from './gallery/photos/c1.jpg'
import c2 from './gallery/photos/c2.jpg'
import c3 from './gallery/photos/c3.png'
import c4 from './gallery/photos/c4.jpg'
import c5 from './gallery/photos/c5.jpg'
import c6 from './gallery/photos/c6.jpg'
import c7 from './gallery/photos/c7.jpg'
import c8 from './gallery/photos/c8.jpg'
import c9 from './gallery/photos/c9.jpg'
import c10 from './gallery/photos/c10.jpg'

import ShowMore from "./showMore/ShowMore";
import lion from './showMore/photos/lion.jpg'
import data from './showMore/photos/data.json'


const cars =[c1,c2,c3,c4,c5,c6,c7,c8,c9,c10]

const slides =[
  {src: photo1},
  {src: photo2},
  {src: photo3},
  {src: photo4},
  {src: photo5},
]

const slidesSmall =[
  {src: small1},
  {src: small2},
  {src: small3},
  {src: small4},
  {src: small5},
]

function App() {
  return (
    <div className="App">
      <h1> Photo Slider</h1>
      <Slider slides={slides} slidesSmall={slidesSmall}/>
      <h1>Zoom in photos</h1>
      <h5>drag your mouse over the photo</h5>
      <div className="zoomInPhotos">
          <div className="zoomInPhotosBox">
            <h3>20% zoom in</h3>
            <PhotoZoom img={p1} zoom={1.2}/>
          </div>
          <div className="zoomInPhotosBox">
            <h3>50% zoom in</h3>
            <PhotoZoom img={p2} zoom={1.5}/>
          </div>
          <div className="zoomInPhotosBox">
            <h3>100% zoom in</h3>
            <PhotoZoom img={p3} zoom={2}/>
          </div>
      </div>
      <h1>Gallery</h1>
      <h5>Click to one of the photos to open the gallery</h5>
      <Gallery imgs={cars}/>
      <h1>Show more button</h1>
      <ShowMore smallText={data.smallText} longText={data.longText} title={'Lion'} pic={lion}/>
    </div>
  );
}

export default App;
