import React from 'react'
import './photoZoomStyle.css'

const PhotoZoom = ({img,zoom}) => {
    const handleMouseEnter = (e) => {
        e.target.style.transform = `scale(${zoom})`
      };
    
      const handleMouseLeave = (e) => {
        e.target.style.transform = `scale(${1})`
      };
  
  
    return (
    <div className='photoZoom__photo-contanier'>
        <img className='photoZoom__img' src={img} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ transition: 'transform 0.3s ease' }}/>
    </div>
  )
}

export default PhotoZoom
