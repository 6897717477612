import React, { useState } from 'react'
import './showMoreStyle.css'

const ShowMore = ({ title, pic, smallText, longText }) => {
    const [shownMore, setShownMore] = useState(false)
    return (
        <div className='ShowMore'>
            <div className='ShowMore__contanier'>
                <h2>{title}</h2>
                <img className='ShowMore__pic' src={pic} />
                {shownMore ?
                    <p>{longText}</p>
                    :
                    <p>{smallText}</p>
                }
                {shownMore ?
                    <button className='ShowMore__btn' onClick={() => setShownMore(false)}>Show less</button>
                    :
                    <button className='ShowMore__btn' onClick={() => setShownMore(true)}>Show more</button>
                }
            </div>
        </div>
    )
}

export default ShowMore
