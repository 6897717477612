import React, { useState, useEffect } from 'react'
import './sliderStyle.css'
import { BsArrowRightCircleFill, BsArrowLeftCircleFill } from 'react-icons/bs'
import { GoPrimitiveDot } from 'react-icons/go'

const Slider = ({ slides,slidesSmall }) => {

    const [currentIndex, setCurrentIndex] = useState(0)
    const [counter, setCounter] = useState(0)

    function handleRightClick() {
        let preCurrentIndex = currentIndex
        if (preCurrentIndex + 1 === slides.length) {
            setCurrentIndex(0)
        }
        else {
            setCurrentIndex(preCurrentIndex + 1)
        }
        setCounter(0)
    }
    function handleLeftClick() {
        let preCurrentIndex = currentIndex
        if (preCurrentIndex === 0) {
            setCurrentIndex(slides.length - 1)
        }
        else {
            setCurrentIndex(preCurrentIndex - 1)
        }
        setCounter(0)
    }


    useEffect(() => {
        const intervalId = setInterval(() => {
          setCounter(prevCounter => prevCounter + 1);
        }, 1000);
    
        return () => clearInterval(intervalId);
      }, []);

    useEffect(() => {
        if(counter === 5){
            setCounter(0)
            handleRightClick()
        }
    }, [counter]);


    return (
        <div className='Slider'>
            <img className='Slider__image-contanier' src={slides[currentIndex].src} />
            <img className='Slider__image-contanier-small' src={slidesSmall[currentIndex].src} />
            <button className='Slider__left-button' onClick={handleLeftClick}><BsArrowLeftCircleFill /></button>
            <button className='Slider__Right-button' onClick={handleRightClick}><BsArrowRightCircleFill /></button>
            <div className='Slider__dot-buttons'>
                {slides.map((e, i) => {
                    return <button key={i} onClick={() => setCurrentIndex(i)} className={i === currentIndex ? 'Slider__active' : 'Slider__inactive'}><GoPrimitiveDot /></button>
                })}
            </div>
        </div>
    )
}

export default Slider
